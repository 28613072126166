<template>
  <div class="mt-3">
    <p class="mb-2 font-weight-bold font-large-3 text-center">
      Change Password
    </p>
    <b-row class="justify-content-md-center">
      <b-col lg="6" class="d-flex align-items-center">
        <b-col>
          <b-card class="mt-2">
            <b-card-text class="text-left font-small-3 ">
              Strong password required. Enter 8-256 characters. Do not include common words or names.
            </b-card-text>
            <ValidationObserver ref="form">
              <b-form @submit.prevent="changePassword">
              <!-- password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="register-password">Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required|min:6"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="register-password">Confirm Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="confirmPassword"
                  rules="required|min:6|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="confirm-password"
                      v-model="confirm_password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="confirm_password"
                      placeholder="············"
                    />
                    <!-- <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility1"
                      />
                    </b-input-group-append> -->
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- login buttons -->
              <b-button
                type="submit"
                variant="outline-primary"
                block
                @click.prevent="changePassword"
              >
                Save
              </b-button>
              </b-form>
            </ValidationObserver>
          </b-card>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, min, confirmed } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import axios from '@/axios.js'

export default {
  mixins: [ togglePasswordVisibility ],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      password: '',
      confirm_password: ''
    }
  },
  mounted() {
    console.log(this);
  },
  methods: {
    changePassword() {
      this.$refs.form.validate().then((isValid) => {
        if (isValid) {
          this.doChangePassword()
        }
      });
    },
    doChangePassword() {
      let confirmToken = this.$route.params.confirmToken;

      axios.put(`/castlab-portal/auth/confirm-user/${confirmToken}`, {
        password: this.password
      }).then((res) => {
        this.$router.push({ name: 'page-login' })
      }).catch((e) => {
        this.$bvToast.toast('Invalid Token', {
          title: 'Failed',
          variant: 'danger',
          solid: true,
        })
        console.log(e)
      })
    }
  }
};
</script>

<style></style>
